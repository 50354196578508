<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item
              :to="{
                name: 'AutoMarketingList',
              }"
              >行銷自動化</b-breadcrumb-item
            >
            <b-breadcrumb-item active>行銷自動化列表</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
        <h4 class="col-12 col-xl-2 mb-2 font-weight-bold">行銷自動化列表</h4>
        <div class="
          col-12 col-xl-6 mb-2
          d-flex
          flex-xl-row
          align-items-start align-items-xl-center
          "
        >
          <b-button
            v-if="checkPermission([consts.MKT_CONFIG_LIST_CREATE])"
            class="flex-shrink-0 mb-2 mb-xl-0 mr-3"
            variant="primary"
            :to="{ name: 'AutoMarketingCreate' }"
            ><i class="fa fa-plus"></i>新增行銷模組</b-button
          >
          <b-input-group>
            <b-form-input v-model="keyword" placeholder="搜尋"></b-form-input>
            <b-input-group-append>
              <b-button @click="getMarketingConfigs"><i class="fa fa-search"></i ></b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <div class="d-flex mb-4 mb-xl-2 justify-content-between">
        <div
          class="
            d-flex
            flex-column flex-xl-row
            align-items-start align-items-xl-center
          "
        >
          <span>設定區間：</span>
          <datepicker
            placeholder="Select Date"
            bootstrap-styling
            format="yyyy-MM-dd"
            :value="startAt"
            @selected="handleStartAtSelected"
          ></datepicker>
          <span class="mx-2">~</span>
          <datepicker
            placeholder="Select Date"
            bootstrap-styling
            format="yyyy-MM-dd"
            :value="endAt"
            @selected="handleEndAtSelected"
          ></datepicker>
        </div>
      </div>
      <b-card-text>
        <div class="row">
          <div class="col-12">
            <b-overlay :show="showLoading" rounded="sm">
              <b-table
                striped
                hover
                responsive
                :items="marketingConfigs"
                :fields="fields"
              >
                <template #cell(actions)="data">
                  <b-button
                    v-if="checkPermission([consts.MKT_CONFIG_LIST_VIEW])"
                    variant="inverse-primary"
                    :to="{
                      name: 'AutoMarketingView',
                      params: { config_id: data.item.id },
                    }"
                  >
                    查看
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.MKT_CONFIG_LIST_MODIFY])"
                    variant="inverse-warning"
                    :to="{
                      name: 'AutoMarketingEdit',
                      params: { config_id: data.item.id },
                    }"
                  >
                    編輯
                  </b-button>
                  <b-button
                    v-if="checkPermission([consts.MKT_CONFIG_LIST_DELETE])"
                    variant="inverse-danger"
                    @click="deleteConfig(data.item)"
                  >
                    刪除
                  </b-button>
                </template>
              </b-table>
            </b-overlay>
          </div>
        </div>
        <div class="d-flex justify-content-center" style="margin-top: 80px">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
          ></b-pagination>
        </div>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import autoMarketingApi from "@/apis/auto-marketing";
import PermissionChecker from '@/utils/PermissionChecker'
import * as consts from '@/consts'
import Datepicker from "vuejs-datepicker";
import { format, addMonths, subMonths, endOfDay, startOfDay } from "date-fns";

export default {
  components: { Datepicker },
  data() {
    return {
      consts: consts,
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      showLoading: true,
      marketingConfigs: [],
      startAt: subMonths(new Date(), 1),
      endAt: addMonths(new Date(), 1),
      keyword: null,
      fields: [
        {
          key: "merchant",
          label: "通路",
          formatter: (value) => {
            return value.type_name
              ? `${value.name} (${value.type_name})`
              : value.name
          },
        },
        {
          key: "title",
          label: "活動名稱",
        },
        {
          key: "start_at",
          label: "開始時間",
          formatter: (value) => {
            return value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : '';
          },
        },
        {
          key: "end_at",
          label: "截止時間",
          formatter: (value) => {
            return value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : '';
          },
        },
        {
          key: "is_enabled",
          label: "是否啟用",
          formatter: (value) => {
            return value ? '啟用中' : '停用中'
          }
        },
        {
          key: "type",
          label: "類型",
        },
        {
          key: "actions",
          label: "操作",
        },
      ],
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
  },
  watch: {
    currentPage() {
      this.getMarketingConfigs();
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      await this.getMarketingConfigs();
    },
    async getMarketingConfigs() {
      this.showLoading = true;
      try {
        let params = {
          per_page: this.perPage,
          page: this.currentPage,
          keyword: this.keyword,
          start_at: this.startAt,
          end_at: this.endAt,
        };

        const { data } = await autoMarketingApi.getMarketingConfigList(params);
        this.marketingConfigs = data.data;
        this.totalRows = data.meta.total;
      } catch (error) {
        console.error(error);
        if (error.status !== 401) {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "取得資料錯誤",
          });
        }
      }
      this.showLoading = false;
    },
    async deleteConfig(config) {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>行銷活動名稱：${config.title}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await autoMarketingApi.removeMarketingConfig(config);
            if (response.status === 200) {
              this.$swal.fire({
                title: "成功",
                type: "success",
                text: "成功刪除 " + config.title,
              });
              this.getMarketingConfigs();
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: '錯誤',
                  type: "error",
                  text: response.data.message,
                });
              }
            }
          }
        });
    },
    checkPermission (permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    handleStartAtSelected(startAt) {
      this.startAt = startOfDay(startAt);
      this.currentPage = 1;
      this.getMarketingConfigs();
    },
    handleEndAtSelected(endAt) {
      this.endAt = endOfDay(endAt);
      this.currentPage = 1;
      this.getMarketingConfigs();
    }
  },
};
</script>

<style scoped>
.btn + .btn {
  margin-left: 0.5rem;
}

.vdp-datepicker {
  min-width: 15rem;
}
</style>
